import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './BePartOfIt.scss';

const BePartOfIt = () => {
  return (
    <section className="be-part-of-it">
      <div className="container">
        <div className="be-part-of-it_center">
          <p className="be-part-of-it_title">Faça parte do Amigos da Poli</p>
          <p className="be-part-of-it_description">Você pode ajudar nosso fundo como voluntário ou através de doações. Faça parte e colabore.</p>
          <div className="d-flex">
            <a href="https://forms.gle/pKq5YYY6LJqoB9r38" target="_black" className="be-part-of-it_button">
              quero ser voluntária(o)!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BePartOfIt;
