import React, { useState } from 'react';
import PropTypes from 'prop-types';

import getImage from '../../utils/getImage';

import ethics from '../../img/about/etica.png';
import governance from '../../img/about/governanca.png';
import independence from '../../img/about/idenpendencia.png';
import transparency from '../../img/about/transparencia.png';

import './OurMission.scss';

const OurMission = ({ missionImage }) => {
  return (
    <section
      className="our-mission"
      style={{
        backgroundImage: `url(${getImage(missionImage)})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <div className="container">
        <p className="our-mission_title">Nossa Missão</p>
        <p className="our-mission_description">Desenvolver o potencial dos alunos da Poli-USP, contribuindo com a excelência de sua formação.</p>
        <div className="our-mission_cards d-flex">
          <div className="our-mission_card">
            <img src={ethics} alt="teste"></img>
            <p className="our-mission_card_title">ÉTICA</p>
          </div>
          <div className="our-mission_card">
            <img src={governance} alt="teste"></img>
            <p className="our-mission_card_title">Governança</p>
          </div>
          <div className="our-mission_card">
            <img src={independence} alt="teste"></img>
            <p className="our-mission_card_title">Independência</p>
          </div>
          <div className="our-mission_card">
            <img src={transparency} alt="teste"></img>
            <p className="our-mission_card_title">Transparência</p>
          </div>
        </div>
      </div>
    </section>
  );
}

OurMission.propTypes = {
  missionImage: PropTypes.string.isRequired,
};

export default OurMission;
