import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/shared/Banner';

import './about-page.scss';
import './product-page.scss';
import Reports from '../components/about/Reports';
import OurStory from '../components/about/OurStory';
import OurMission from '../components/about/OurMission';
import CorporateGovernance from '../components/about/CorporateGovernance';
import Board from '../components/about/Board';
import BePartOfIt from '../components/about/BePartOfIt';
import BoardMemberModal from '../components/about/BoardMemberModal';


export const AboutPageTemplate = ({ banner, history, mission, governance, board }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [userModal, setUserModal] = useState(null);

  const handleViewMore = (data) => {
    setUserModal(data);
    setIsModalActive(true);
  };

  const close = () => {
    setIsModalActive(false);
  };

  const handleAccordion = (data) => {
    data.status = !data.status;
    setAccordion([...accordion]);
  };

  const [accordion, setAccordion] = useState([]);
  return (
    <div id="about-page">
      <Banner {...banner}></Banner>
      <OurStory />
      <OurMission missionImage={mission.image} />
      <CorporateGovernance />
      <Board
        board={board}
        handleAccordion={handleAccordion}
        handleViewMore={handleViewMore} />
      <BePartOfIt />
      <BoardMemberModal
        isModalActive={isModalActive}
        userModal={userModal}
        close={() => close()} />
      <Reports reports={[2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]}
               financialReports={[2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022]} />
    </div>
  );
};

AboutPageTemplate.propTypes = {
  banner: PropTypes.object,
  history: PropTypes.object,
  mission: PropTypes.object,
  governance: PropTypes.object,
  board: PropTypes.array,
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AboutPageTemplate banner={frontmatter.banner} history={frontmatter.history} mission={frontmatter.mission} governance={frontmatter.governance} board={frontmatter.board} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const abountPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          subtitle
        }
        history {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        mission {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        governance {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        board {
          name
          profiles {
            name
            img {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imgModal {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            charge
            description
          }
        }
      }
    }
  }
`;
