import React, { useState } from 'react';
import PropTypes from 'prop-types';

import getImage from '../../utils/getImage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './BoardMemberModal.scss';

const BoardMemberModal = ({ isModalActive, userModal, close }) => {
  return isModalActive && (
    <div className="modal is-active" onClick={() => close()}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="modal_header">
          <span
            aria-hidden="true"
            role="button"
            onClick={() => close()}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        <div className="modal_body">
          <div className="modal_image">
            <img src={getImage(userModal.imgModal)} alt="teste"></img>
          </div>
          <div class="modal_content">
            <p className="modal_content_title">{userModal.name}</p>
            <span className="modal_content_office">{userModal.charge}</span>
            <span className="modal_content_description">{userModal.description}</span>
          </div>
        </div>
      </div>
    </div>
  )
};

BoardMemberModal.propTypes = {
  isModalActive: PropTypes.bool,
  userModal: PropTypes.shape({
    imgModal: PropTypes.string,
    charge: PropTypes.string,
    description: PropTypes.string,
  }),
  close: PropTypes.func,
};

export default BoardMemberModal;
