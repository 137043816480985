import React, { useState } from 'react';
import PropTypes from 'prop-types';

import organogram from '../../img/about/Organograma12.png';

import './CorporateGovernance.scss';

const CorporateGovernance = () => {
  return (
      <section
        className="corporate-governance"
      >
        <div className="container">
          <h2 className="corporate-governance_title text-primary">
            GOVERNANÇA CORPORATIVA
          </h2>
          <p className="corporate-governance_description">
            Desde a concepção do Fundo Patrimonial Amigos da Poli, somos pautados por uma estrutura e
            modelo de atuação diferenciados, que trouxeram à Escola Politécnica uma inteligência na captação e
            alocação de recursos para além dos moldes de gestão tradicionais. Nos orgulhamos de possuir uma estrutura
            organizacional robusta, com órgãos de Conselho, Comitês e Diretorias em linha com as melhores práticas de
            governança.
          </p>
        </div>
        <img className="mb-50" src={organogram} alt="teste"></img>
        <div className="corporate-governance_button">
          <a href="http://estatuto.amigosdapoli.com.br/" target="_black">
            baixe o nosso estatuto
          </a>
        </div>
      </section>
  );
}

export default CorporateGovernance;
