import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import getImage from '../../utils/getImage';
import './Board.scss';

const Board = ({ board, handleAccordion, handleViewMore }) => {
  return (
    <section className="board">
      <div className="container">
        <h2 className="board_title text-primary">CONSELHEIROS E DIRETORIA</h2>
        <p className="board_description">Conheça nossa liderança</p>
      </div>

      <div className="container">
        <div className="accordion">
          {board.map((boardGroup, index) => {
            return (
              <React.Fragment key={boardGroup.name}>
                <div className="board_accordion">
                  <div
                    className="board_info"
                    onClick={() => handleAccordion(boardGroup)}>
                    <div className="board_info_title">{boardGroup.name}</div>
                    <div className="board_info_icon">
                      <FontAwesomeIcon
                        color='#30BCDA'
                        icon={boardGroup.status ? faAngleUp : faAngleDown} />
                    </div>
                  </div>
                  <div className="board_info_accordion justify-content-center">
                    {boardGroup.status && boardGroup.profiles.map((profile, i) => {
                      return (
                        <div className="board_info_content" key={i}>
                          <div className="board_info_content_image">
                            <img src={getImage(profile.img)} alt="teste"></img>
                          </div>
                          <div>
                            <p className="board_info_content_title">{profile.name}</p>
                            <span className="board_info_content_office">{profile.charge}</span>
                            <span
                              className="board_info_content_button text-primary"
                              aria-hidden="true"
                              role="button"
                              onClick={() => handleViewMore(profile)}>
                              Ver perfil
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
}

Board.propTypes = {};

export default Board;
