import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DFpdf2022 from '../../pdf/DF_2022.pdf';
import DFpdf2021 from '../../pdf/DF_2021.pdf';
import DFpdf2020 from '../../pdf/DF_2020.pdf';
import DFpdf2019 from '../../pdf/DF_2019.pdf';
import DFpdf2018 from '../../pdf/DF_2018.pdf';
import DFpdf2017 from '../../pdf/DF_2017.pdf';
import DFpdf2016 from '../../pdf/DF_2016.pdf';
import DFpdf2015 from '../../pdf/DF_2015.pdf';
import DFpdf2014 from '../../pdf/DF_2014.pdf';
import DFpdf2013 from '../../pdf/DF_2013.pdf';

import './Reports.scss';

const Reports = ({ reports, financialReports }) => {
  const DFReports = {
    2013: DFpdf2013,
    2014: DFpdf2014,
    2015: DFpdf2015,
    2016: DFpdf2016,
    2017: DFpdf2017,
    2018: DFpdf2018,
    2019: DFpdf2019,
    2020: DFpdf2020,
    2021: DFpdf2021,
    2022: DFpdf2022,
  };

  return (
     <section className="annual-reports">
        <div>
          <h2 className="text-primary">RELATÓRIOS ANUAIS</h2>
          <p className="annual-reports_description">Veja tudo que realizamos nos últimos anos</p>
          <div className="d-flex justify-content-center">
            { reports.sort().map(year => {
              return (
                <a href={`https://relatorioanual.amigosdapoli.com.br/relatorio-${year}.pdf`} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">
                  {year}
                </a>
              );
            }) }
          </div>
        </div>
        <div>
          <p className="annual-reports_description"><br></br><br></br>Confira nossos demonstrativos financeiros</p>
          <div className="d-flex justify-content-center">
            { financialReports.sort().map(year => {
              return (
                <a href={DFReports[year]} target="_blank" rel="noreferrer" className="annual-reports_number text-primary">
                  {year}
                </a>
              );
            }) }
          </div>
        </div>
      </section>
  );
}

Reports.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.number).isRequired,
  financialReports: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Reports;
