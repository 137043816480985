import React, { useState } from 'react';
import PropTypes from 'prop-types';

import timeLine from '../../img/about/TIMELINE_6.png';
import './OurStory.scss';

const OurStory = ({ storyTitle }) => {
  return (
    <section className="our-story" >
      <div className="container">
        <h2 className="text-primary">{storyTitle}</h2>
      </div>
      <img src={timeLine} alt="Linha do tempo"></img>
    </section>
  );
}

OurStory.propTypes = {
  storyTitle: PropTypes.string,
};

OurStory.defaultProps = {
  storyTitle: '',
};

export default OurStory;
